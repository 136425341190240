<template>
    <div class="home-container">
        <el-container>
            <el-header style="height: 82px">
                <div>
                    <img src="../assets/title.jpg" style="width: 285px;height: 33px;margin: 25px 0 24px 33px "/>
<!--                    <span style="font-size: 36px">自动识别称重系统</span>-->
                </div>
                <div style="display: flex;justify-content: center;align-items:center;">
                  <div class="default_btn" type="text" @click="open">
                    <img class="title_img" src="../assets/camera.jpg"/>
                    <span class="title_text">查看监控</span>
                  </div>
                  <span class="separator">|</span>
                  <div class="default_btn" type="text">
                    <img class="title_img" src="../assets/user.png"/>
                    <span class="title_text" v-if="username">{{username}}</span>
                    <span class="title_text" v-else>未登录</span>
                  </div>
                  <span class="separator">|</span>
                  <div class="default_btn" type="text" @click="loginOut">
                    <img class="title_img" src="../assets/logout.jpg"/>
                    <span class="title_text">退出</span>
                  </div>
                </div>
            </el-header>
            <el-container>
                <el-aside :width="isCollapse ? '60px':'200px'">
                    <div style="color: #fff;" @click="isCollapse=!isCollapse">
                      <i v-if="!isCollapse" class="el-icon-s-fold"></i>
                      <i v-else class="el-icon-s-unfold"></i>
                    </div>
                    <!-- 导航菜单 -->
                    <el-menu
                            default-active="2"
                            class="el-menu-vertical-demo"
                            background-color="#42454C"
                            text-color="#fff"
                            :unique-opened="true"
                            :collapse="isCollapse"
                            :router="true"
                            :default-active="activePath"
                            :collapse-transition="false"
                            active-text-color="#ffd04b">
                        <el-submenu :index="item.id+''" v-for="item in menulists" :key="item.id">
                            <template slot="title">
                                <i :class="objicon[item.id]" style="color: #42B7FB"></i>
                                <span>{{item.menu}}管理</span>
                            </template>
                            <el-menu-item :index="'/'+item.id" @click="activeSave('/'+item.id)">
                                <template slot="title">
                                    <i class="el-icon-arrow-right"></i>
                                    <span>{{item.menu}}列表</span>
                                </template>
                            </el-menu-item>
                        </el-submenu>

                    </el-menu>

                </el-aside>
                <el-main>
                    <router-view></router-view>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>

    import global from "../global/global";

    export default {
        name: 'Home',
        data() {
            return {
                //左侧菜单
                menulists: [],
                objicon: {
                    1: 'el-icon-user',
                    2: 'el-icon-office-building',
                    3: 'el-icon-s-order',
                    4: 'el-icon-coordinate',
                    5: 'iconfont icon-baobiao'
                },
                //是否水平折叠菜单
                isCollapse: false,
                //当前激活的路径
                activePath: '',
                username: ''
            }
        },
        methods: {
            //退出登录
            loginOut() {
                window.sessionStorage.clear();
                this.$router.replace({path: '/login'}).then(() => {location.reload()})
            },
            //点击导航，有激活的状态
            activeSave(path) {
                this.activePath = path;
                window.sessionStorage.setItem('activePath', path);
            },
            open() {
              window.open(global.cameraUrl);
            }
        },
        created() {
            //导航激活的状态
            this.activePath = window.sessionStorage.getItem('activePath');
            //获取左侧菜单
            this.$http.get('menus').then(res => {
                if (res.data.code !== 200) return this.$message.error('请求数据失败！');
                /*		this.$message.success('请求成功！');*/
                this.menulists = res.data.data;

            })
        },
      mounted() {
          this.$data.username = window.sessionStorage.getItem('username');
      }
    }
</script>

<style scoped="scoped">
    .title {

    }
    .home-container {
        height: 100%;
        background: #c4c4c4;
    }

    .el-container {
        height: 100%;
    }

    .el-header {
      background: #42B7FB;
      display: flex;
      justify-content: space-between;
      color: #fff;
      font-size: 20px;
    }

    /*.el-header img {*/
    /*    vertical-align: middle;*/
    /*    width: 60px;*/
    /*    height: 65px;*/
    /*}*/

    .el-aside {
        background: #42454C;
        height: 100%;
        box-sizing: border-box;
    }

    .el-main {
        background: #ececec;
    }

    .btn_camera {
      float: right;
    }
    .el-icon-c {
      background: url("../assets/camera.jpg") center no-repeat;
      background-size: cover;
    }
    .default_btn {
      font-size: 16px;

      font-weight: 500;
      color: #fff;
      background: #42B7FB;
      padding: 0 10px;
    }
    .default_btn:hover {
      cursor: pointer;
    }
    .separator {
      color: #fff;
      margin: 0 5px;
    }
    .title_text {
      line-height: 10px;
      vertical-align: middle;
    }
    .title_img {
      height: 18px;
      width: 18px;
      margin-right: 5px;
      vertical-align: middle;
    }
</style>
