import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import Print from 'vue-print-nb'
import 'element-ui/lib/theme-chalk/index.css';
import { VueJsonp } from 'vue-jsonp';
import moment from 'moment'

Vue.use(Print);
Vue.use(ElementUI);
Vue.use(VueJsonp);

Vue.filter('dateFormat', function (dateStr,pattern = "yyyy-MM-DD HH:mm:ss") {
    return moment(dateStr).format(pattern);
})

import axios from 'axios';

Vue.prototype.$http = axios;
axios.defaults.withCredentials = true;
axios.defaults.timeout = 5000;

import tree from 'vue-table-with-tree-grid'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

//全局注册组件
Vue.use(VueQuillEditor);
Vue.component('tree', tree);

import global from "./global/global.js"
Vue.prototype.global = global

//基础地址
// axios.defaults.baseURL = 'http://172.50.200.121:8088/';
// axios.defaults.baseURL = 'http://47.100.238.11:8088/';
//  axios.defaults.baseURL = 'http://192.168.4.117:8088/';
//  axios.defaults.baseURL = 'https://ydzy.ntiov.com:10002/';
axios.defaults.baseURL = global.apiUrl;

import './assets/css/global.css';

import './assets/fonts/iconfont.css';

//this.$route
axios.interceptors.request.use(config => {
    config.headers.Authorization = window.sessionStorage.getItem('token');
    return config;
})

axios.interceptors.response.use(response => {
        if (response.data.code === 602) { //未登录跳转
            window.sessionStorage.clear();
            setInterval(() => {
                router.replace({path: '/login'})
                    .then(() => {location.reload()})
                    .catch(() =>{})
            }, 2000)
        }
        return response;
    }, error => {
        if(error.message.includes('timeout')) {
            ElementUI.Notification({
                title: '警告',
                message: '请求超时，请稍后再试！',
                type: 'warning'});
            return Promise.reject(error);
        }
        if (error && error.response) {
            switch (error.response.status) {
                case 601: ElementUI.Notification({
                    title: '警告',
                    message: '您没有权限进行该操作，请联系管理员！',
                    type: 'warning'}); break;
                default: ElementUI.Notification({
                    title: '错误',
                    message: '出错，请联系管理员！',
                    type: 'error'});
            }
        }else {
            error.message ='连接服务器失败!'
        }
        return error;
    }
)


Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
