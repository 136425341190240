<template>
  <div class="login_container">
      <!-- 登录盒子  -->
    <div class="login_box">
      <!-- 头像 -->
      <div class="avatar_box ">
          <img src="../assets/weight_from.jpg" alt="">
      </div>
      <div class="printer">
        <el-button @click="show(), show1()" v-print='printMe' icon="el-icon-printer">打印</el-button>
      </div>

        <!-- 登录表单 -->
      <el-form :model="weighForm" status-icon ref="editWeighFormRel" label-width="100px"
                 class="weigh_form" v-bind:class="{show1:temp1}">
            <el-row>
                <el-col :span="10">
                    <el-form-item label="车牌号">
                        <el-input v-model="weighForm.weighCar" readonly></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="首次称重">
                    <el-input v-model.number="weighForm.weighFirst" readonly></el-input>
                  </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                  <el-form-item label="发货单位">
                    <el-input v-model="weighForm.weighDelivery" readonly></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="二次称重">
                    <el-input v-model="weighForm.weighSecond" readonly></el-input>
                  </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="收货单位">
                        <el-input v-model="weighForm.weighAccept" readonly></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="净重">
                    <el-input v-model.number="weighForm.weighLast" readonly></el-input>
                  </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                  <el-form-item label="货物名称">
                    <el-input v-model="weighForm.weighGoods" readonly></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="货物价格">
                    <el-input v-model="weighForm.weighPrice" readonly></el-input>
                  </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item label="货物数量">
                      <el-input v-model="weighForm.weighSpec" readonly></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="司机">
                    <el-input v-model="weighForm.weighDriver" readonly></el-input>
                  </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <el-form-item class="btn">
                        <el-button type="primary" style="width: 300px;" @click="close">关闭当前窗口，{{ times }} 秒后自动关闭</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

      <el-form :model="weighForm" status-icon ref="editWeighFormRel" label-width="100px"
                 class="weigh_form2" v-bind:class="{show:temp}" id="printMe">
        <div class="print_form" v-for="i in 3">
          <div class="title">
            <p>{{global.tableTitle}}</p>
            <p>称重计量单</p>
          </div>
          <div class="print-time">
            <p>打印时间: {{dateFormat(new Date())}}</p>
          </div>
          <el-row>
            <el-col :span="11">
              <el-form-item>
                        <span slot="label">
                          <span class="print_item w3"><strong>车牌号</strong></span>
                        </span>
                <el-input v-model="weighForm.weighCar" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" class="print_col">
              <el-form-item>
                <span slot="label">
                  <span class="print_item w2"><strong>首</strong></span>
                  <span class="print_item"><strong>重</strong></span>
                </span>
                <el-input v-model="weighForm.weighFirst" readonly><i slot="suffix" class="kg">千克</i></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item>
                <span slot="label">
                  <span class="print_item"><strong>发货单位</strong></span>
                </span>
                <el-input v-model="weighForm.weighDelivery" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" class="print_col">
              <el-form-item>
                <span slot="label">
                  <span class="print_item w2"><strong>次</strong></span>
                  <span class="print_item"><strong>重</strong></span>
                </span>
                <el-input v-model="weighForm.weighSecond" autocomplete="off" readonly><i slot="suffix" class="kg">千克</i></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item>
                <span slot="label">
                  <span class="print_item"><strong>收货单位</strong></span>
                </span>
                <el-input v-model="weighForm.weighAccept" autocomplete="off" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" class="print_col">
              <el-form-item>
                <span slot="label">
                  <strong><span class="print_item w2">净</span></strong>
                  <strong><span class="print_item">重</span></strong>
                </span>
                <el-input v-model="weighForm.weighLast" readonly><i slot="suffix" class="kg">千克</i></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="11">
              <el-form-item>
                <span slot="label">
                  <span class="print_item"><strong>货物名称</strong></span>
                </span>
                <el-input v-model="weighForm.weighGoods" readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" class="print_col">
              <el-form-item>
                <span slot="label">
                  <span class="print_item"><strong>货物价格</strong></span>
                </span>
                <el-input v-model="weighForm.weighPrice" autocomplete="off" readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="global.company === 'nt'">
            <el-col :span="11">
              <el-form-item>
                <span slot="label">
                  <span class="print_item w3"><strong>确认人</strong></span>
                </span>
                <el-input readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" class="print_col">
              <el-form-item>
                <span slot="label">
                  <span class="print_item w2"><strong>经</strong></span>
                  <span class="print_item"><strong>理</strong></span>
                </span>
                <el-input readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-else>
            <el-col :span="7">
              <el-form-item>
                <span slot="label">
                  <span class="print_item w3"><strong>司磅员</strong></span>
                </span>
                <el-input readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7" class="print_col_bottom">
              <el-form-item>
                <span slot="label">
                  <span class="print_item w3"><strong>监磅员</strong></span>
                </span>
                <el-input readonly></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7" class="print_col_bottom">
              <el-form-item>
                <span slot="label">
                  <span class="print_item w2"><strong>经</strong></span>
                  <span class="print_item"><strong>理</strong></span>
                </span>
                <el-input readonly></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        </el-form>
    </div>
  </div>
</template>

<script>
    export default {
        data() {
            return {
                //当前时间
                date: new Date(),
                //数据绑定
                weighForm: {},
                //
                queryInfo: {
                    query: '44444',
                },
                styleObj: {
                    display: "none"
                },
                temp: true,
                temp1: false,
                printMe: {
                    id: 'printMe',
                    beforeOpenCallback(vue) {
                      vue.temp1 = false;
                      vue.temp = true;
                    }
                },
                times: 30
            }
        },
        methods: {
            dateFormat(time) {
                let date = new Date(time);
                let year = date.getFullYear();
                /* 在日期格式中，月份是从0开始的，因此要加0
                * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                * */
                let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
                let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
                let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
                let seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
                // 拼接
                return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
            },
            show() {
                this.temp = false
            },
            show1() {
                this.temp1 = true
            },
            close() {
              this.$router.push('/begin')
            }
        },
        created() {
            let licence = window.sessionStorage.getItem('licence');
            this.$http.post('weigh_info/' + licence).then(res => {
                if (res.data.code !== 200) return this.$message.error({ message:res.data.msg, center:true });
                this.weighForm = res.data.data;
            })
        },
        mounted() {
            //显示当前日期时间
            let _this = this// 声明一个变量指向Vue实例this，保证作用域一致
            this.timer = setInterval(() => {
                _this.date = new Date(); // 修改数据date
            }, 1000),
            this.timer = setInterval(() => {
                //跳转的页面写在此处
                this.$router.push({path: '/begin'});
            }, this.times * 1000),
            this.time = setInterval(() => {
              this.times--;
              if (this.times == 0) {
                clearInterval(this.time);
              }
            }, 1000)
        },
        beforeDestroy() {
            if (this.timer) {
                clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
            }
        }
    }
</script>

<style>
    @page {
        margin-bottom: 1mm;
        margin-top: 1mm;
    }

    .weigh_form .el-form-item__label {
        color: white !important;
    }

    .weigh_form2 .el-form-item__label {
        color: black !important;
    }


</style>

<style scoped>
  .print-time {
    margin-left: 17px;
    color: black;
    font-weight: bolder
  }
  .title {
    text-align: center;
    font-size: 24px;
    font-weight: bolder;
    letter-spacing: 5px;
  }

  .show {
    display: none;
  }

  .show1 {
    display: none;
  }

  .login_container {
    width: 100%;
    height: 100%;
    background: url("../assets/weight_bg.jpg") no-repeat center;
    background-size: 100% 100%;
    position: relative;
  }

  .login_box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .avatar_box {
    padding: 10px;
  }

  .avatar_box img {
    width: 1000px;
    height: 500px;
    position: relative;
  }

  .weigh_form {
    width: 745px;
    height: 375px;
    position: absolute;
    top: 85px;
    left: 150px;
    padding-top: 10px;
  }

  .weigh_form .el-row {
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
  }

  .weigh_form .el-input {
    width: 180px;
    margin-right: 100px;
  }

  /deep/ .weigh_form .el-form-item__label {
    width: 75px !important;
    text-align: justify;
    vertical-align: middle;
    text-align-last: justify;
    margin-left: 20px;
  }

  .weigh_form2 {
    //top: 7%;
    //position: absolute;
    //bottom: 0;
    //width: 100%;
    //padding-bottom: -20px;
    //box-sizing: border-box;
    border: 1px solid white;
  }

  .btn {
    display: flex;
    font-size: 50px;
    margin-left: 100px;
  }

  .printer {
    position: absolute;
    top: 5px;
    right: 35px;
  }

  .printer .el-button {
    width: 130px;
    font-size: 20px;
  }

  .print_item {
    color: black;
    font-size: 18px;
  }
  .w2 {
    letter-spacing: 2em;
  }
  .w3 {
    letter-spacing: 0.5em;
    margin-right: -0.5em
  }
  .print_col {
    margin-left: 55px
  }
  .kg {
    color: black;
    font-style: normal;
    font-weight: bolder;
    margin-right: 8px
  }
  /deep/.print_form .el-input__inner {
    font-size: 18px;
    color: black;
    border: 3px solid #000;
    border-radius: 0;
  }
  .print_form {
    border: 1px solid black;
    width: 1060px;
    height: 510px;
    margin-top: 35px;
    padding-left: 10px;
  }
</style>
