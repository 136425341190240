// const apiUrl = "https://ydzy.ntiov.com:10002/";
// const wsUrl = "ws://172.50.200.121:22600/weight/subscribe"
// const cameraUrl = "http://172.50.200.215"
// const tableTitle = "启东中远海运海洋工程有限公司";
// const company = "qd";

const apiUrl = "https://ntzy.ntiov.com:10001/";
const wsUrl = "ws://172.50.38.12:22600/weight/subscribe"
const cameraUrl = "http://172.50.38.4"
const tableTitle = "南通中远海运海洋工程有限公司";
const company = "nt";

// const apiUrl = "http://192.168.4.117:8088";
// const wsUrl = "ws://127.0.0.1:22600/weight/subscribe"

export default {
    apiUrl,
    wsUrl,
    tableTitle,
    cameraUrl,
    company
};
