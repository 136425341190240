<template>
    <div class="login_container">
        <!-- 登录盒子  -->
        <div class="login_box">
            <!-- 登录表单 -->
            <el-form class="login_form" :model="loginForm" ref="LoginFormRef" :rules="loginFormRules">
                <!-- 用户名 -->
                <el-form-item class="form_item" prop="username">
                    <img class="account" src="../assets/account.jpg" alt="用户名"/>
                    <el-input v-model="loginForm.username" placeholder="请输入用户名"></el-input>
                    <span class="line"></span>
                </el-form-item>
                <!-- 密码 -->
                <el-form-item class="form_item" prop="password">
                    <img class="password" src="../assets/password.jpg" alt="密码"/>
                    <el-input type="password" v-model="loginForm.password" placeholder="请输入密码"></el-input>
                    <span class="line"></span>
                </el-form-item>
                <!-- 按钮 -->
                <el-form-item>
                    <el-button class="login_btn" type="primary" @click="login">登 录</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                //数据绑定
                loginForm: {
                    username: '',
                    password: ''
                },
                //表单验证规则
                loginFormRules: {
                    username: [
                        {required: true, message: '请输入登录名', trigger: 'blur'},
                        {
                            min: 2,
                            max: 10,
                            message: '登录名长度在 2 到 10 个字符',
                            trigger: 'blur'
                        }
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {
                            min: 3,
                            max: 15,
                            message: '密码长度在 6 到 15 个字符',
                            trigger: 'blur'
                        }
                    ]
                }
            }
        },
        //添加行为，
        methods: {
            //添加表单重置方法
            resetLoginForm() {
                //this=>当前组件对象，其中的属性$refs包含了设置的表单ref
                //   console.log(this)
                this.$refs.LoginFormRef.resetFields()
            },
            login() {
                //点击登录的时候先调用validate方法验证表单内容是否有误
                this.$refs.LoginFormRef.validate(async valid => {
                    //如果valid参数为true则验证通过
                    if (!valid) {
                        return
                    }

                    //发送请求进行登录
                    const {data: res} = await this.$http.post('login', this.loginForm);
                    // console.log("www:" + res)
                    if(res === undefined) {
                        return this.$message.error('登录失败！')
                    }
                    if (res.code !== 200) {
                        return this.$message.error('登录失败！' + res.msg)
                    }
                    this.$message.success('登录成功！')
                    //保存token
                    window.sessionStorage.setItem('token', res.data.token)
                    window.sessionStorage.setItem('username', res.data.username)
                    window.sessionStorage.setItem('role', res.data.role)
                    // 导航至/home
                    await this.$router.push({path: '/home'})
                })
            }
        }
    }
</script>

<style scoped>
  .login_container {
    height: 100%;
    width: 100%;
    background: url("../assets/login_bg.jpg") no-repeat center;
    background-size: 100% 100%;
    position: relative;
  }

  .login_box {
    width: 600px;
    height: 350px;
    background: url('../assets/login_form.jpg') no-repeat center;
    background-size: 90% 90%;
    position: absolute;
    left: 90%;
    top: 50%;
    transform: translate(-90%, -50%);
  }

  .login_form {
    width: 400px;
    position: absolute;
    top: 120px;
    left: 140px;
  }

  .login_btn {
    width: 260px;
    margin: 10px 0 10px 10px;
  }

  /deep/ .el-input input {
    background-color: rgba(255, 255, 255, 0) !important;
    border: 0;
    border-radius: 0;
    color: #fff;
    font-size: 16px;
  }
  .el-input {
    width: 250px;
    margin-left: 30px;
  }
  .account, .password {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px
  }
  .img {
    margin: 0;
  }
  .form_item {
    width: 300px;
    height: auto;
    border-bottom: 2px solid #fff;
    color: #fff;
    margin-left: 50px;
  }
</style>
