import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../components/Login.vue'
import Weighbridge from '../components/Weighbridge.vue'
import Begin from '../components/Begin.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Login,
        meta: {
            title: '登录'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: '登录'
        }
    },
    {
        path: '/weighbridge',
        name: 'Weighbridge',
        component: Weighbridge,
        meta: {
            title: '称重信息'
        }
    },
    {
        path: '/begin',
        name: 'Begin',
        component: Begin,
        meta: {
            title: '开始称重'
        }
    },
    {
        path: '/home',
        redirect: '/welcome',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        children: [
            {
                path: '/welcome',
                name: 'welcome',
                component: () => import('../components/Welcome'),
                meta: {
                    title: '欢迎'
                }
            },

            {
                path: '/1',
                name: 'users',
                component: () => import('../components/user/User'),
                meta: {
                    title: '用户管理'
                }
            },
            {
                path: '/2',
                name: 'supplier',
                component: () => import('../components/user/Supplier'),
                meta: {
                    title: '供应商管理'
                }
            },
            {
                path: '/3',
                name: 'pick',
                component: () => import('../components/pick/Pick'),
                meta: {
                    title: '提货单'
                }
            },
            {
                path: '/4',
                name: 'weigh',
                component: () => import('../components/weigh/Weigh'),
                meta: {
                    title: '称重单'
                }
            },
            {
                path: '/5',
                name: 'camera',
                component: () => import('../components/camera/Camera'),
                meta: {
                    title: '录像'
                }
            }
        ]
    }
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    const token = window.sessionStorage.getItem('token');
    document.title = to.meta.title
    if (to.path !== '/login') {
        if (to.path == '/begin' || to.path == '/weighbridge') {
            next()
        } else {
            if (!token) {
                next('/login')
            } else {
                next()
            }
        }
    } else {
        next()
    }

})

export default router
